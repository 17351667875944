import { useEffect } from "react";

import { BuildCard } from "./Services";
import styles from "./styles/Knowledge.module.css"

//BANNER
import Banner from "./Banner";
import banner_9 from "../assets/banners/banner_9.jpg"
import banner_10 from "../assets/banners/banner_10.jpg"

//IMAGES
import image_one from "../assets/screens/startups.png"
import image_ten from "../assets/screens/fcdo.png"

const Casestudyhome = () => {
    useEffect(()=>{
        document.title = "Maitri Capital - Case Studies"
    })

    const initialImages = [
        {
            source:image_ten,
            date:"NOVEMBER 2024",
            heading:"Case Studies - Unlocking Kenya’s Trade Potential: Key Sectors for Growth and Global Competitiveness",
            link:"../case_studies/Understanding_Kenyas_Trade_Landscape_Video_Case_Studies",
        },
        {
            source:image_one,
            date:"MARCH 2023",
            heading:"Understanding The Kenyan Startup Ecosystem",
            smallText:"A look at the evolution of the Kenyan Startup Ecosystem",
            link:"../case_studies/Understanding_The_Kenyan_Startup_Ecosystem",
        },
    ]

    const text = "Diversified Knowledge"
        return (
            <div className="knowledgeContainer">
                <Banner backgroundImg={banner_10} extraImg={banner_9}  mainText={text} />
                <div className={styles.knowledgeHead}>
                    <h3>Case Studies </h3>
                </div>
                <div className={styles.knowledgeMain}>
                    <div className={styles.dividerDiv}></div>
                    <div className="row">
                        {
                            initialImages.map((image, index) => (
                                <BuildCard img_details={ image } styles={styles.infoHolder} key={index} />
                            ))
                        }
                    </div>
                </div>
            </div>
        );
}

export default Casestudyhome