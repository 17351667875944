import { useEffect } from "react";
import styles_awi from "./styles/Awi.module.css"

//BANNER
import Banner from "./Banner";
import banner_9 from "../assets/banners/banner_9.jpg"
import banner_10 from "../assets/banners/banner_10.jpg"

import full_report_link from "../assets/knowledge/Unlocking_Kenya’s_Trade_Potential_Key_Sectors_for_Growth_and_Global_Competitiveness_Full Report.pdf"
import exec_summary from "../assets/knowledge/Unlocking_Kenya’s_Trade_Potential_Key_Sectors_for_Growth_and_Global_Competitiveness_Abridged Report.pdf"

const FcdoComponent = () => {

  useEffect(()=>{
    document.title = "Maitri Capital - FCDO"
  })

  const text = "Diversified Knowledge"
  return (
    <div className="awi_holder">
      <Banner backgroundImg={banner_10} extraImg={banner_9}  mainText={text} />
      <div className={styles_awi.awiHead}>
        <h3>Unlocking Kenya’s Trade Potential: Key Sectors for Growth and Global Competitiveness </h3>

        <p className={styles_awi.p1}>
            Explore our newly released report, "Unlocking Kenya’s Trade Potential: Key Sectors for Growth and Global Competitiveness," commissioned by the UK Government’s 
            UK Tech Hub Kenya and authored by Maitri Capital. 
        </p>
        <p>
            This report serves as a strategic guide to help Kenya harness its strengths across key sectors and enhance its position in the global market.
        </p>
        <p>
            This strategic roadmap identifies priority sectors where Kenya can enhance local production, 
            reduce reliance on imports, and build resilience in global markets. 
            Discover actionable insights in key areas—energy, pharmaceuticals, 
            textiles, agriculture, and more—that position Kenya for sustainable growth and international competitiveness.
        </p>

        <p>
          Read the Full Report <a href={full_report_link} target="_blank" rel="noopener noreferrer"> <i className="fa fa-download"></i> here</a>
        </p>
        <p>
          Read the Abridged Report <a href={exec_summary} target="_blank" rel="noopener noreferrer"> <i className="fa fa-download"></i> here </a>
        </p>
        <p>
          Read the case studies <a href="/knowledge/Understanding_Kenyas_Trade_Landscape_Video_Case_Studies"><i className="fa fa-download"></i> here</a>
        </p>
      </div>
    </div>
  )
}

export default FcdoComponent