import { useEffect } from "react";

import { BuildCaseStudyDiv } from "./Services";
import styles from "./styles/Knowledge.module.css"

//BANNER
import Banner from "./Banner";
import banner_9 from "../assets/banners/banner_9.jpg"
import banner_10 from "../assets/banners/banner_10.jpg"

//DOCUMENTS

const Fcdocases = () => {
    useEffect(()=>{
        document.title = "Maitri Capital -  Case Studies"
    })

    const imageDivsOne = [
        {
            heading:"Trific - A Video Case Study on Understanding Kenya's Trade Landscape",
            ytLink:"https://youtube.com/embed/WWp17tzo3nU"
        },
        {
            heading:"Mabati Rolling Mills - A Video Case Study on Understanding Kenya's Trade Landscape",
            ytLink:"https://youtube.com/embed/XdT9VX7AF3M"
        },
        {
            heading:"Stir Me - A Video Case Study on Understanding Kenya's Trade Landscape",
            ytLink:"https://youtube.com/embed/jBsWW2MnF1A"
        },
    ]

    const imageDivsTwo = [
        {
            heading:"Spiro - A Video Case Study on Understanding Kenya's Trade Landscape",
            ytLink:"https://youtube.com/embed/ZFPVMZhiE_c"
        },
        {
            heading:"Tasa Pharma - A Video Case Study on Understanding Kenya's Trade Landscape",
            ytLink:"https://youtube.com/embed/DbmThpQU4KY"
        }
    ]

    const text = "Diversified Knowledge"
    return (
        <div className="knowledgeContainer">
            <Banner backgroundImg={banner_10} extraImg={banner_9}  mainText={text} />
            <div className={styles.knowledgeHead}>
                <h3>Understanding Kenya's Trade Landscape: Video Case Studies </h3>
            </div>
            <div className={styles.knowledgeMain}>
                <div className={styles.dividerDiv}></div>
                <div className="row">
                   {
                        imageDivsOne.map((img, index) => (
                            <BuildCaseStudyDiv caseDetails={img} styles={styles.infoHolder} key={index} />
                        ))
                   }
                </div>
                <div className="row">
                   {
                        imageDivsTwo.map((img, index) => (
                            <BuildCaseStudyDiv caseDetails={img} styles={styles.infoHolder} key={index} />
                        ))
                   }
                </div>
            </div>
        </div>
    )
}

export default Fcdocases